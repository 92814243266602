import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const TestPrivateRoutes = () => {
    const dispatch = useDispatch();
    const getAuth = async () => {
        try {
            const response = await axios.get('http://localhost:8000/auth/check-auth/', { withCredentials: true });
            dispatch({ type: 'auth/handleLogin', payload: response.data });
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        getAuth();
    }, []);
    
    return <Outlet />;
}

export default TestPrivateRoutes;