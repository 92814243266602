import { Navigate, Outlet } from "react-router-dom";


const PrivateRoutes = () => {
    const getNikFromSession = () => {
        return sessionStorage.getItem('nik') && sessionStorage.getItem('role');
    }
    let auth = getNikFromSession();
    return (
        auth ? <Outlet /> : <Navigate to={'/'} />
    );
}

export default PrivateRoutes