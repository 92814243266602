import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from "./LoginPage/LoginPage"
import Homepage from "./Homepage/Homepage";
import MVDWarroom from './MVDWarroom/MVDWarroom';
import MVDInvestor from './MVDInvestor/MVDInvestor';
import "./App.css";
import PrivateRoutes from './auth/PrivateRoutes';
import PageNotFound from './PageNotFound';
import TestPrivateRoutes from './auth/TestPrivateRoutes';
import TestLoginPage from './auth/TestLoginPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<Homepage />} />
          <Route path="/warroom" element={<MVDWarroom />} />
          <Route path="/investor" element={<MVDInvestor />} />
        </Route>
        <Route element={<TestPrivateRoutes />}>
          <Route path="/test-home" element={<Homepage />} />
          <Route path="/login" element={<TestLoginPage />} />
        </Route>
        <Route
          path="*"
          element={<PageNotFound />}
        />
      </Routes>
    </Router>
  );
}

export default App;