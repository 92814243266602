import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleLogin: (state, action) => {
      state.isAuthenticated = action.payload.is_authenticated;
      state.username = action.payload.username;
      console.log('action', action.payload)
    },
    handleLogout: (state) => {
      state.isAuthenticated = false;
    },
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;
export default authSlice.reducer;
