import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sinarmasLogo from '../assets/sinarmasLogo.svg';
import warroomIcon from '../assets/warroomIcon.svg';
import investorIcon from '../assets/investorIcon.svg';
import './Homepage.css';

const Homepage = () => {
  const navigate = useNavigate();

  const [role, setRole] = useState("");
  const [isInvestor, setIsInvestor] = useState({});
  const [isProduction, setIsProduction] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getNikFromSession = () => {
    return sessionStorage.getItem("nik");
  };

  const getRole = async (nik) => {
    setIsLoading(true);
    const url = `https://genai-api.nawatech.co/api/users/get/${nik}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'X-API-Key': 'e1a38197a2c0fa11176188725b6e206dc824203f',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setRole(data.data.role);
        setIsInvestor(data.data.role.investor);
        setIsProduction(data.data.role.production);
      } else {
        console.error('Error fetching role:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching role:', error);
    }

    setIsLoading(false);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('nik');
    navigate('/');
  };

  const handleClick = (path) => {
    navigate(`/${path}`);
  };

  useEffect(() => {
    const nik = getNikFromSession();
    if (nik) {
      getRole(nik);
    }
  }, [])

  useEffect(() => {
    console.log('investor', isInvestor);
    console.log('production', isProduction)
  }, [isInvestor, isProduction])

  if (isLoading) return null;

  return (
    <div className="homepage">
      <div className="banner">
        <img src={sinarmasLogo} alt="Sinarmas Logo" />
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      <hr className="separator-line" />
      <div className="homepage-container">
        {isProduction ? <div className="box" onClick={() => handleClick("warroom")}>
          <div className="box-title">MVD Production</div>
          <img src={warroomIcon} alt="Warroom Icon" className="box-icon" />
        </div> : null}
        {isInvestor ? <div className="box" onClick={() => handleClick("investor")}>
          <div className="box-title">MVD Investor</div>
          <img src={investorIcon} alt="Investor Icon" className="box-icon" />
        </div>
          : null}
      </div>
    </div>
  );
};

export default Homepage;