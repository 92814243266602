import React, { useState, useEffect, useRef } from "react";
import { models } from "powerbi-client";
import SlidingDiv from "../SlidingDiv";
import "./MVDWarroom.css";

const MVDWarroom = () => {
  const embedRef = useRef(null);

  const getNikFromSession = () => {
    return sessionStorage.getItem("nik");
  }

  const getRoleFromSession = () => {
    const list = []
    list.push(sessionStorage.getItem("role"));
    return list;
  }

  const getRole = async (nik) => {
    const url = `https://genai-api.nawatech.co/api/users/get/${nik}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'X-API-Key': 'e1a38197a2c0fa11176188725b6e206dc824203f',
        },
      });

      if (response.ok) {
        const data = await response.json();
        sessionStorage.setItem('role', data.data.role.production.name);
      } else {
        console.error('Error fetching role:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching role:', error);
    }
  };

  const getAccessToken = async () => {
    const url = "https://genai-api.nawatech.co/powerbi/get-access-token";

    try {
      const response = await fetch(url, {
        method: 'POST',
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error('Error fetching access token:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  };

  const getTokenPowerBI = async (accessToken) => {
    const requestBody = JSON.stringify({
      "accessLevel": "View",
      "identities": [
        {
          "datasets": ["72d33eb1-9e25-4669-a8fb-3925e5f40e09"],
          "roles": getRoleFromSession(),
          "username": getNikFromSession(),
        },
      ],
      "datasets": [
        { "id": "72d33eb1-9e25-4669-a8fb-3925e5f40e09" },
      ],
      "reports": [
        {
          "allowEdit": false,
          "id": "6ec4cc08-c706-4f89-8431-1e03bad8ae1c",
        },
      ],
      "lifetimeInMinutes": 50,
    });

    const response = await fetch('https://api.powerbi.com/v1.0/myorg/GenerateToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: requestBody,
    });
    const data = await response.json();
    return data;
  }

  const embedPowerBIReport = async () => {
    try {
      const token = await getAccessToken();
      const tokenPowerBI = await getTokenPowerBI(token.access_token);
      const embedConfig = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: tokenPowerBI.token,
        embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=6ec4cc08-c706-4f89-8431-1e03bad8ae1c',
        id: '6ec4cc08-c706-4f89-8431-1e03bad8ae1c',
        settings: {
          filterPaneEnabled: true,
          navContentPaneEnabled: false,
        },
      };

      window.powerbi.embed(embedRef.current, embedConfig);
    } catch (error) {
      console.error('Failed render powerbi report:', error);
    }
  };

  const updateTokenPowerBI = async () => {
    try {
      const token = await getAccessToken();
      const tokenPowerBI = await getTokenPowerBI(token.access_token);
      let embedPowerBI = window.powerbi.get(embedRef.current);
      await embedPowerBI.setAccessToken(tokenPowerBI.token);
    } catch (error) {
      console.error("Failed Update token powerBI");
    }
  }

  useEffect(() => {
    const nik = getNikFromSession();
    if (nik) {
      getRole(nik);
    }
  }, []);

  useEffect(() => {
    const role = getRoleFromSession();
    if (role) {
      embedPowerBIReport();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTokenPowerBI();
    }, 15 * 60 * 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="App" style={{ position: "relative" }}>
      <SlidingDiv />
      <div className="" ref={embedRef} style={{ height: '100vh', width: '100%', borderWidth: '0' }} />
    </div>
  );
};

export default MVDWarroom;
