import React, { useState, useEffect } from 'react';
import SinarmasImage from "../assets/sinarmas.png"
import { useNavigate } from 'react-router-dom';
import "./LoginPage.css"
import useRole from '../hooks/useRole';

const LoginPage = () => {
  const [token, setToken] = useState('');
  const [nik, setNik] = useState('');
  const {getRole} = useRole();
  const navigate = useNavigate();

  // uncomment once sso is ready

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const queryToken = urlParams.get('token'); 
  //   const queryNik = urlParams.get('nik'); 

  //   if (queryToken && queryNik) {
  //     sessionStorage.setItem('nik', queryNik);
  //     navigate('/home');
  //   }
  // }, [navigate]);

  // const handleLogin = () => {
  //   //change to sinarmas sso url
  //   const ssoUrl = `http://localhost:3001?nik=${nik}`;

  //   window.location.href = ssoUrl;
  // };

  const handleLogin = async () => {
    sessionStorage.clear();
    sessionStorage.setItem('nik', nik);
    await getRole(nik);
    navigate('/home');
  };

  return (
    <div className="login-container">
      <img src={SinarmasImage} alt="Sinarmas Logo" className="logo" /> 
      <div className="disclaimer">
        Please do not put in your real password, this is just for testing purposes.
      </div>
      <div className="login-form">
        <input
          type="text"
          placeholder="Enter NIK"
          value={nik}
          onChange={(e) => setNik(e.target.value)}
          required={true}
        />
        <input
          type="password"
          placeholder="Enter Password"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          required={true}
        />
        <button onClick={handleLogin}>Login with SSO</button>
      </div>
    </div>
  );
};

export default LoginPage;