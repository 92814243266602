import { useEffect, useState } from "react"

export default function useRole() {
  const getRole = async (nik) => {
    const url = `https://genai-api.nawatech.co/api/users/get/${nik}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'X-API-Key': 'e1a38197a2c0fa11176188725b6e206dc824203f',
        },
      });

      if (response.ok) {
        const data = await response.json();
        sessionStorage.setItem('role', data.data.role);
      } else {
        console.error('Error fetching role:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching role:', error);
    }
  };

  return { getRole }
}