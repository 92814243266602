import React, { useState } from "react";
import "./EvaButton.css";
import Eva from "../assets/eva.jpeg";
import Draggable from "react-draggable";

const EvaButton = ({ onClick }) => {
  const [isMove, setIsMove] = useState(false);
  const onClickMoveable = () => {
    if (isMove) {
      setIsMove(false)
    } else {
      setIsMove(true)
    }
  }
  return (
    <Draggable disabled={!isMove} defaultPosition={{ x: 0, y: 10 }}>
      <div className={isMove ? 'moveable' : ''}>
        <button className="eva-btn" onClick={onClick} disabled={isMove}>
          <div className="eva-btn-text">EVA</div>
        </button>
        <img className="eva-btn-image" alt="EVA" src={Eva} onDoubleClick={onClickMoveable} />
      </div>
    </Draggable>

  );
};

export default EvaButton;