import React from 'react';
import { useSelector } from 'react-redux';

const TestLoginPage = () => {
    const handleLogin = () => {
        // Add your login logic here
        window.location.href = 'https://genai-api.nawatech.co/auth/login';
    };
    const username = useSelector((state) => state.auth.username);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    console.log(isAuthenticated);
    return (
        <div className='login-container'>
            <h1>username : {username}</h1>
            <h1>is Auth : {isAuthenticated ? "Authenticated" : "Not Authenticate"}</h1>
            <div className='login-form'>
                <button className='btn-base font-base' onClick={handleLogin}>Login</button>
            </div>
        </div>
    );
};

export default TestLoginPage;